<template>
    <div class="profile-container" v-if="translates && translates[langUrl]">
        <div class="user-profile">
            <h3 class="user-profile__title">{{ translates[langUrl].pageTitle[lang] }}</h3>
            <div class="user-profile__container">
                <div class="user-profile__main-info">
                    <div class="user-profile__main-info__line">
                        <div class="user-profile__main-info__line__key">{{ translates[langUrl].fieldName_firstname[lang] }}:</div>
                        <div class="user-profile__main-info__line__value">{{ user.firstname }}</div>
                    </div>
                    <div class="user-profile__main-info__line">
                        <div class="user-profile__main-info__line__key">{{ translates[langUrl].fieldName_lastname[lang] }}: </div>
                        <div class="user-profile__main-info__line__value">{{ user.lastname }}</div>
                    </div>
                    <div class="user-profile__main-info__line">
                        <div class="user-profile__main-info__line__key">{{ translates[langUrl].fieldName_birthday[lang] }}: </div>
                        <div class="user-profile__main-info__line__value">{{ user.birthday | moment('DD.MM.YYYY') }}</div>
                    </div>
                    <div class="user-profile__main-info__line">
                        <div class="user-profile__main-info__line__key">{{ translates[langUrl].fieldName_city[lang] }}: </div>
                        <div class="user-profile__main-info__line__value">{{ user.city }}</div>
                    </div>
                    <div class="user-profile__main-info__line">
                        <div class="user-profile__main-info__line__key">{{ translates[langUrl].fieldName_phone[lang] }}: </div>
                        <div class="user-profile__main-info__line__value">{{ user.phone }}</div>
                    </div>
                    <div class="user-profile__main-info__line">
                        <div class="user-profile__main-info__line__key">{{ translates[langUrl].fieldName_email[lang] }}: </div>
                        <div class="user-profile__main-info__line__value">
                            {{ user.email }}
                            <img v-if="user.emailConfirmed" src="../../assets/CheckMarkGreen.svg">
                            <template v-else>
                                <template v-if="showEmailConfirm">
                                    <v-text-field
                                        v-model="emailCode"
                                        hide-details
                                        @keyup.enter="confirmEmail"
                                        :label="translates[langUrl].label__emailCode[lang]"
                                        ref="emailCode-input"
                                    ></v-text-field>
                                    <img src="../../assets/CheckMarkPink.svg" @click.stop="confirmEmail" class="user-profile__main-info__line__value__submit">
                                </template>
                                <a v-else href="#" @click.stop="updateEmail">{{ translates[langUrl].button_confirmEmail[lang] }}</a>
                            </template>
                        </div>
                    </div>
                    <div class="user-profile__main-info__line">
                        <div class="user-profile__main-info__line__key">{{ translates[langUrl].fieldName_telegram[lang] }}: </div>
                        <div class="user-profile__main-info__line__value">
                            <template v-if="user.telegramChatID">
                                Подписан <img src="../../assets/CheckMarkGreen.svg">
                            </template>
                            <template v-else>
                                <a :href="telegramUserBotLink" target="_blank">{{ translates[langUrl].button_telegramSubscribe[lang] }}</a>
                            </template>
                        </div>
                    </div>
                    <div class="user-profile__main-info__line">
                        <div class="user-profile__main-info__line__key">{{ translates[langUrl].fieldName_company[lang] }}: </div>
                        <div class="user-profile__main-info__line__value">{{ user.company }}</div>
                    </div>
                    <div class="user-profile__main-info__line">
                        <div class="user-profile__main-info__line__key">{{ translates[langUrl].fieldName_site[lang] }}: </div>
                        <div class="user-profile__main-info__line__value">{{ user.site }}</div>
                    </div>
                    <div class="user-profile__main-info__line">
                        <div class="user-profile__main-info__line__key">{{ translates[langUrl].fieldName_social[lang] }}: </div>
                        <div class="user-profile__main-info__line__value">{{ user.social }}</div>
                    </div>
                </div>
                <div :style="`background-image: url(${ imageSrc(user.avatar) })`" class="user-profile__avatar"></div>
            </div>
            <!-- <div class="user-profile__main-info__line">
                <div class="user-profile__main-info__line__key">Отзывы:</div>
                <div class="user-profile__main-info__line__value">{{ user.reviews }}</div>
            </div> -->
            <div class="user-profile__main-info__line">
                <div class="user-profile__main-info__line__key">{{ translates[langUrl].fieldName_about[lang] }}:</div>
                <div class="user-profile__main-info__line__value">{{ user.about }}</div>
            </div>
            <div 
                v-if="user.images && user.images.length > 0" 
                class="user-profile__input-container" 
                style="display:flex; justify-content: space-between"
            >
                <div class="user-profile__input-container__input-name">{{ translates[langUrl].images[lang] }}</div>
                <Splide
                    class="user-profile__splide"
                    :options="{ rewind: true, type: 'loop', perPage: tourSplideCounter, perMove: 1, pagination: false }"
                >
                    <template v-for="(picture, index) in user.images">
                        <SplideSlide :key="index">
                            <div
                                :key="index"
                                :style="`background-image: url(${ imageSrc(picture) })`"
                                class="img_slot"
                            ></div>
                        </SplideSlide>
                    </template>
                </Splide>
                <!-- <div class="user-profile__img-container">
                    <template v-for="(picture, index) in user.images">  
                        <div
                            :key="index"
                            :style="`background-image: url(${ imageSrc(picture) })`"
                            class="img_slot"
                        >
                        </div>
                    </template>
                </div> -->
            </div>
            <BaseButton
                style="width: 100%"
                class="button-fill"
                @click="profile"
            >{{ translates[langUrl].button_edit[lang] }}</BaseButton>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import BaseButton from '../../components/common/BaseButton.vue'
    import { imageSrc } from '@/helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';


    export default {
        name: 'Profile',
        metaInfo() {
            return {
                title: this.translates[this.langUrl]? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            BaseButton,
            Splide,
            SplideSlide
        },
        data: () => ({
            imageSrc,
            tourSplideCounter: 3,
            langUrl: '/user/profile',
            emailCode: '',
            showEmailConfirm: false
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', {
                user: state => state.user
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            telegramUserBotLink() {
                return `https://t.me/${process.env.VUE_APP_TELEGRAM_USER_BOT}`;
            }
        },
        methods: {
            async profile() {
                await this.$router.push({ name: 'user-profile-edit' });
            },
            async updateEmail() {
                await store.dispatch('auth/emailUpdate', { email: this.user.email });
                this.showEmailConfirm = true;
                
                await new Promise((resolve, reject) => setTimeout(resolve, 100));
                this.$refs['emailCode-input'].focus();
            },
            async confirmEmail() {
                await store.dispatch('auth/emailConfirm', { code: this.emailCode });
                await store.dispatch('auth/fetch');
                this.showEmailConfirm = false;
            },
        }
    }
</script>


<style lang="scss">
.profile-container {
    @media all and (max-width: 1464px) {
        width: calc(100% - 64px);
    }
        max-width: 1400px;
}

.user-profile {
    max-width: 800px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #3A4256;
    &__title {
        font-weight: bold;
        font-size: 38px;
        line-height: 46px;
        color: #273155;
        margin: 40px 0;
        @media all and (max-width: 768px) {
            font-size: 25px; 
        }
    }
    &__container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        @media all and (max-width: 768px) {
            flex-direction: column;
            margin: auto;
        }
    }
    &__avatar {
        height: 160px;
        width: 160px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        // margin-right: 30px;
        @media all and (max-width: 768px) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
    &__main-info {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        // margin-right: 150px;
        &__line {
            max-width: 600px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 30px;
            font-size: 16px;
            line-height: 19px;
            &__key {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-right: 30px;
                white-space: nowrap;
                color: #273155;
            }
            &__value {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                max-width: 430px;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #686F88;

                .v-input {
                    max-width: 150px;
                    padding: 0;
                    margin-left: 10px;
                }
                a, img {
                    margin-left: 10px;
                }
                &__submit {
                    cursor: pointer;
                }
            }
        }
    }
    &__img-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        @media all and (min-width: 768px) {
            justify-content: flex-start;
        }
    }
    &__splide {
        width: 500px;
    }
    .img_slot {
        position: relative;
        overflow: hidden; 
        margin-bottom: 12px;
        width: 150px;
        height: 110px;
        background-size: cover;
        background-position: center;
        // @media all and (min-width: 768px) {
        //     width: 175px;
        //     height: 166px;
        // }
        border-radius: 10px;
    }
}
</style>